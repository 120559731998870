export const MENU_LAYOUTS = {
  BURGER: "burguer",
  ICONS: "icons",
  DEFAULT: "default",
  CP: "cp",
};

export const TIME_TO_WAIT = 7000;

export const getPrefix = () => {
  let prefix = ''
  const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`
  switch(currentEnv){
    case 'dev':
    case 'dev_ssr':
      prefix = '/develop';
    break;
    case 'qa':
    case 'qa_ssr':
      prefix = '/qa';
    break;
    default:
    case 'prod':
      prefix = '';
    break;
  }
  return prefix;
}


export const P2LOGIN_EXCEPTIONS_URLS = (prefix) => {  
  return [
  `${prefix}/seguimiento-de-pedidos`,
  `${prefix}/dispos`,
  `${prefix}/moviles`,
  `${prefix}/tablets`,
  `${prefix}/smart-tv`,
  `${prefix}/hogar`,
  `${prefix}/accesorios`,
  `${prefix}/gaming`, 
  `${prefix}/smartwatches`,
  `${prefix}/portatiles`,
  `${prefix}/equipamiento`,
  `${prefix}/packs`,
  `${prefix}/regalos-dia-de-la-madre`,
  `${prefix}/catalogo-dispositivos`,
  `${prefix}/black-friday`,
  `${prefix}/cyber-monday`,
  `${prefix}/regalos-dia-de-la-madre`,
  `${prefix}/moviles-reacondicionados`,
  `${prefix}/moviles-baratos`,
  `${prefix}/moviles-5g`,
  `${prefix}/moviles-con-mejor-camara`,
  `${prefix}/apple`,
  `${prefix}/samsung`,
  `${prefix}/xiaomi`,
  `${prefix}/iphone`,
  `${prefix}/iphone-reacondicionados`,
  `${prefix}/iphone-16`,
  `${prefix}/iphone-15`,
  `${prefix}/iphone-14`,
  `${prefix}/iphone-13`,
  `${prefix}/iphone-12`,
  `${prefix}/iphone-11`,
  `${prefix}/samsung-s24`,
  `${prefix}/samsung-s23`,
  `${prefix}/samsung-s22`,
  `${prefix}/xiaomi-redmi-note-12`,
  `${prefix}/xiaomi-redmi-note-13`,
  `${prefix}/xiaomi-13`,
  `${prefix}/xiaomi-14`,
  `${prefix}/ps5`,
  `${prefix}/nintendo-switch`,
  `${prefix}/xbox`,
  `${prefix}/gafas-realidad-virtual`,
]
};
