import React, { useState } from "react";
import PropTypes from "prop-types";
import LinkGroupHeader from "../link-group-header";
import LinkList from "../link-list";

const LinkGroup = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenHandler = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <div className="group">
        <LinkGroupHeader
          onClick={onOpenHandler}
          title={title}
          isOpen={isOpen}
        />
        {items && (
          <LinkList
            title={title}
            items={items}
            isOpen={isOpen}
            className="link-list"
          />
        )}
      </div>
      <style jsx>{`@media screen and (min-width: 768px) {
  .group {
    overflow: hidden;
  }
  .group :global(.link-list) {
    display: block;
    transition: all 2s linear;
    max-height: 1000px;
  }
}
@media screen and (min-width: 1024px) {
  .group :global(.link-list) {
    display: block;
  }
}
`}</style>
    </>
  );
};

export default LinkGroup;

LinkGroup.propTypes = {
  /**
   * {
   *  "info": "Texto del título del bloque de links",
   *  "kind": "both",
   *  "beautifulName": "Título del bloque"
   * }
   */
  title: PropTypes.string,

  /**
   * {
   *  "info": "Lista de objetos con los campos título y link correspondiente a los enlaces que cuelgan del bloque",
   *  "kind": "both",
   *  "beautifulName": "Titulos y links de enlaces"
   * }
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
    })
  ),
};

LinkGroup.defaultProps = {
  title: "",
  items: [],
};
