import React, { Component } from "react";
import dynamic from "next/dynamic";

import IconGlyph from '@/bit/components/components.icon-glyph'
import { connect } from "react-redux";

import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import CustomLink from "@/bit/components/components.custom-link";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const ModalMenu = dynamic(() => import("../../../modals/burger"));

class BurgerMenu extends Component {
  constructor(props) {
    super(props);
  }

  handleBurgerModal = () => {
    const { addModal, location, account, modals, privateArea, logged, forceSelect } = this.props;
    let time = 0;
    const device = getDeviceKindDependsOnViewPort();
    const bgColor = device === "MOBILE" ? "white" : "blur";
    setTimeout(() => {
      addModal({
        id: "menu",
        Element2Show: ModalMenu,
        modalProps: {
          closeIdByClickingOutside: "",
          contentPosition: "left--top",
          opacityLayoutColor: bgColor,
          inSceneEffect: "fadeInLeft",
          contentInsideContainer: false,
          outSceneEffect: "fadeOutLeft",
          closeByClickingOutside: true,
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: {         
          account: { ...account },
          privateArea: privateArea,
          forceSelect,
          logged: logged,
          location
        },
      });
    }, time);
  };

  render() {
    return (
      <CustomLink
        onClick={this.handleBurgerModal}
        title="Menú"
        ariaLabel="Menú"
        className="button menu"
        taggingInfo={{
          componentName: "header",
          componentContent: "openBurger",
          componentVariation: "all",
          componentPerso: "",
          componentProduct: "",
        }}
        cyData="Header burger"
      >
      <IconGlyph 
        id="menu-m"       
        className="menu-m"
        width={24}
        height={24}       
        color="grey6" 
      />
      </CustomLink>
    );
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modalHistory,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
