import React from "react";
import PropTypes from "prop-types";
import CustomLink from "@/bit/components/components.custom-link";
import Text, {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_ALIGN,
  TEXT_KIND,
  TEXT_HEADING_LEVEL,
} from "@/bit/components/components.text";
import { TAGGIN_INFO, grid, colors } from "../../constants";

const LinkList = ({ title, items, isOpen }) => {
  const open = isOpen ? "open" : "";
  return (
    <>
      <ul className={`link-list ${open}`} data-cy={`Footer list ${title}`}>
        {items.map((item, n) => {
          return (
            <li className="link-item" key={`${item?.link}-${item?.text}-${n}`}>
              <CustomLink
                taggingInfo={{ ...TAGGIN_INFO, componentContent: item?.text }}
                target={item?.target}
                href={item?.link}
                title={item?.title || item?.text || ""}
                ariaLabel={item?.ariaLabel || item?.text || ""}
              >
                <Text
                  text={item?.text}
                  color={TEXT_COLOR.GREY5}
                  size="body1"
                  kind={TEXT_KIND.SPAN}
                  align={TEXT_ALIGN.LEFT}
                />
              </CustomLink>
            </li>
          );
        })}
        <style jsx>{`.link-list {
  transition: max-height 200ms linear;
  display: none;

  &.open {
    display: block;
  }
  .link-item {
    margin-left: 1rem;
    :global(.next-link) {
      display: flex;
      align-items: center;
      min-height: 3rem;
      :global(.text:hover) {
        text-decoration: solid underline currentColor 1px;
        text-underline-offset: 4px;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .link-list {
    display: block;
    .link-item {
      margin-left: 0;
      margin-bottom: 0;
      :global(.next-link) {
        min-height: 1.875rem;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .link-list {
    .link-item {
      margin-bottom: 4px;
      transition: background-color 200ms linear;
      :global(.next-link) {
        min-height: 2.25rem;
      }
    }
  }
}
`}</style>
      </ul>
    </>
  );
};

export default LinkList;

LinkList.propTypes = {
  title: PropTypes.string,
  /**
   * {
   *  "info": "Lista de objetos con los campos título, link y target correspondiente a los enlaces que cuelgan del bloque",
   *  "kind": "both",
   *  "beautifulName": "Lista de titulos, links y targets de enlaces"
   * }
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
    })
  ),

  /**
   * {
   *  "info": "Booleano que indica si mostrar o no el componente",
   *  "kind": "both",
   *  "beautifulName": "Mostrar componente"
   * }
   */
  isOpen: PropTypes.bool,
};

LinkList.defaultProps = {
  title: "",
  items: [],
  className: "",
  isOpen: false,
};
