import React from "react";
import PropTypes from "prop-types";

let API = "{host}/api/v1/get-glyph";
const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;

const ICON_GLYPH_KINDS = ["s", "m", "l"];

export const ICON_GLYPH_KINDS_V1 = {
  S: 's',
  M: 'm',
  L: 'l'
};

const checkPropNull = (prop) => {
  return prop === null || prop === 0 || prop === '0' || prop === "";
}

const checkKind = (id) => {
  // Si no se proporciona un sufijo (kind), establecer el valor por defecto como "m"
  if (!id.includes("-")) {
    id += "-m";
  }
  const words = id.split("-");
  const validKinds = ICON_GLYPH_KINDS;
  const suffix = words.pop();
  if (validKinds.includes(suffix)) {
    return id;
  } else {
    return id+'-m';
  }
}

const buildId = (id, kind) => {
  let correctId = null;
  // Para los casos que viene el Id sin '-' y en camel case
  if (/[A-Z]/.test(id)) {
    correctId = id.split(/(?=[A-Z])/).join('-');
    correctId.toLowerCase()
  }
  if (kind && correctId) {
    id = `${correctId}-${kind}`
  } else if (kind && !correctId) {
    id = `${id}-${kind}`
  } else if (!kind && correctId) {
    id = checkKind(correctId)
  } else {
    id = checkKind(id)
  }
  
  return id;
}

/**
 * Componente que permite dibujar un icono
 */
const IconGlyph = (props) => {
  let { id, color, className, priority, kind, alt, title, cms, cmsHandler } = props;
  let { width, height, insetPosition, dataCy, layoutSize } = props;
  const finalId = buildId(id, kind);
  if(!dataCy){
    dataCy = `${id}`
  }
  const size = {};
  let finalTitle = ""
  if (title) {
    finalTitle = title;
  }

  if (currentEnv !== 'prod') {
    // API = API.replace('{host}', 'https://dev.ssr.col.movistar.es');
    API = API.replace('{host}', 'https://ssr.col.movistar.es');
  } else {
    API = API.replace('{host}', 'https://ssr.col.movistar.es');
  }


  /* Damos valor a la otra coordenada en caso de no indicarse */
  if (width && !height) {
    // Si no está definido el height se le da el valor del width 
    size.height = width;
    if (!layoutSize || !layoutSize.height) {
      layoutSize = { ...layoutSize, width };
    }
  } else if (height && !width) {
    // Si no está definido el width se le da el valor del height 
    size.width = "auto";
    if (!layoutSize || !layoutSize.width) {
      layoutSize = { ...layoutSize, height };
    }
  } else if (width && height) {
    // Si ambos están definidos y son diferentes, establecer ambos como el valor de width
    size.width = width;
    size.height = height;
    if (insetPosition) {
      layoutSize = {width, height};
    }
  }


  let loadingAttribute = 'eager';
  let fetchPriorityAttribute = 'high';
  if(!priority){
    loadingAttribute = 'lazy';
    fetchPriorityAttribute = 'none'
  }

  let finalClassName = 'svg-layout-size'
  if (className) {
    finalClassName = `${finalClassName} ${className}`;
  }

  if (cms && cmsHandler) {
    finalClassName = `${finalClassName} element-editable`;
  }

  let finalAlt = alt || '';
  if (!finalAlt && finalId) {
    finalAlt = finalId
  }

  // cms placeholder
  if (cms && (id === "" || checkPropNull(width))) {
    if (cmsHandler) {
      return (
        <div 
          className={`${finalClassName} cms-icon-placeholder`} 
          style={{ ...(layoutSize || {})}}
          onClick={() => cmsHandler({ ...props, name: 'icon-glyph'})}>
          <img
            title={finalTitle}
            src={`${API}/soporte-tecnico-m/20/white`}
            width={20}
            height={20}
          />
        </div>
      );
    } else return null;
  }

  if (!id) {
    return null;
  }

  return (
    <div 
      className={finalClassName} 
      style={{ ...(layoutSize || {})}}
      onClick={cms && cmsHandler ? () => cmsHandler({ ...props, name: 'icon-glyph'}) : null}>
      <img
        title={finalTitle}
        data-cy={dataCy}
        src={`${API}/${finalId}/${size.height || height}/${color}`}
        width={size.width || width}
        height={size.height || height}
        style={{
          ...insetPosition,
          height: size.height || height,
          width: size.width || width,
        }}
        alt={finalAlt}
        loading={loadingAttribute}
        fetchpriority={fetchPriorityAttribute}
      />
    </div>
  );
};


export default IconGlyph;

IconGlyph.propTypes = {
  /**
   * {
   *  "info": "Nombre de icono y trazo a mostrar.",
   *  "kind": "both",
   *  "beautifulName": "Id de icono"
   * }
   */
  id: PropTypes.string,
  /**
   * {
   *  "info": "Colorres que tendrá el icono",
   *  "kind": "both",
   *  "beautifulName": "Colores para cada trazo"
   * }
   */
  color: PropTypes.string,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Añade etiqueta dom para priorizar su carga, útil si dicho icono, está visible, en el aterrizaje a una página",
   *  "kind": "dev",
   *  "beautifulName": "Carga prioritaria"
   * }
   */
  priority: PropTypes.bool,
  /**
   * {
   *  "info": "Tipo de trazo del icono",
   *  "kind": "both",
   *  "beautifulName": "Trazo",
   *  "values": "./index.js:ICON_GLYPH_KINDS_V1"
   * }
   */
  kind: PropTypes.string,
  /**
   * {
   *  "info": "Texto alternativo del icono para accesibilidad",
   *  "kind": "dev",
   *  "beautifulName": "Alt"
   * }
   */
  alt: PropTypes.string,
  /**
   * {
   *  "info": "Ancho en píxeles del icono. Obligatorio excepto cuando se especifica el height o layoutSize.",
   *  "kind": "both",
   *  "beautifulName": "Ancho"
   * }
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * {
   *  "info": "Alto en píxeles del icono. Obligatorio excepto cuando se especifica el width o layoutSize.",
   *  "kind": "dev",
   *  "beautifulName": "Altura"
   * }
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  dataCy: PropTypes.string,
  /**
   * {
   *  "info": "Objeto que define al tamaño al lienzo que contiene dicho icono",
   *  "kind": "dev",
   *  "beautifulName": "Tamaño lienzo"
   * }
   */
  layoutSize: PropTypes.shape({
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};