import React from "react";
import PropTypes from "prop-types";
import MediaIcon from "./media-icon";
import Text, { TEXT_COLOR } from "@/bit/components/components.text";

const SocialMedia = ({ data }) => {
  return (
    <>
      <div className="social-media">
        <div className="siguenos" data-cy="Footer síguenos">
          <Text text="Síguenos" color={TEXT_COLOR.GREY3} />
        </div>
        <ul className="social-media-logos" data-cy="Footer social media logos">
          {data &&
            data.map((e, i) => (
              <MediaIcon
                icon={e?.icon}
                link={e?.link}
                target={e?.target}
                key={`${e?.icon}-item.${i}`}
              />
            ))}
        </ul>
      </div>
      <style jsx>{`.social-media {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
}
.social-media .social-media-logos {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 30rem;
}

@media screen and (min-width: 768px) {
  .social-media {
    padding: 0;
  }

  .social-media .social-media-logos {
    padding: 0;
    gap: 3.125rem;
    max-width: 25.5rem;
  }
  .social-media .siguenos {
    display: none;
  }
}
`}</style>
    </>
  );
};

export default SocialMedia;

SocialMedia.propTypes = {
  /**
   * {
   *  "info": "Lista compuesta por el id de los iconos, links y targets asociados",
   *  "kind": "both",
   *  "beautifulName": "Lista de ids de iconos, links y targets"
   * }
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string,
    })
  ),
};

SocialMedia.defaultProps = {
  data: [],
};
