import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { getTimehash } from '@gcloud-npm/utils.page.get-time-hash'

const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;
const DEFAULT_SIZES = [
  '750w',
  '828w',
  '1080w',
  '1200w',
  '1920w',
]

const VALID_EXTENSIONS = [
  'avif',
  'webp',
  // "jxl",
];

const isAemImage = (path) => {
  return path && path.startsWith("/content/dam/movistar");
}

const isSSRImage = (path) => {
  return path && path.startsWith("/static/pictures");
}

export const makeUpSrcForApi = (src = '') => {
  let pathName = src;
  try {
    // eliminamos hosts
    pathName = pathName.replace('https://', '');
    pathName = pathName.replace('http://', '');
    pathName = pathName.replace('prod.cms.col.movistar.es', '');
    pathName = pathName.replace('qa.cms.col.movistar.es', '');
    pathName = pathName.replace('prod.adb.col.movistar.es', '');
    pathName = pathName.replace('www.movistar.es', '');
    pathName = pathName.replace('col10mgmt.tsm.inet', '');
    pathName = pathName.replace('movistar.es', '');
    pathName = pathName.replace('//', '/');

    let forceAemStatic = false;
    //Force VCM folders to go to AEM
    const foldersToGoToAEM = [
      "/estaticos/imagenes/terminales-moviles",
      "/estaticos/imagenes/terminales-fijos",
      "/estaticos/imagenes/dispositivos",
      "/estaticos/imagenes/accesorios"
    ]
    forceAemStatic = !!foldersToGoToAEM.find((folderPath) => pathName.startsWith(folderPath));
    if (forceAemStatic && !isAemImage(pathName)) {
      pathName = `/content/dam/movistar/${pathName}`;
    }

    let srcSplit = pathName.split('?');
    srcSplit = srcSplit[0];
    srcSplit = srcSplit.split('.');
    pathName = srcSplit.join('.');



  } catch (e) {
    console.error(e);
  }
  return pathName;
};

const getApiHost = (pathname) => {
  let API = "{host}/api/v3/get-pic";
  // Caso productivo
  if (currentEnv === "prod") {
    API = "https://ssr.col.movistar.es/api/v3/get-pic";
    if (isSSRImage(pathname)) {
      API = "https://ssr.col.movistar.es";
    }
    // caso de local
  } else if(currentEnv === 'local' && isSSRImage(pathname)) {
    API = "https://dev.ssr.col.movistar.es";
    // entornos comunes
  } else if (isAemImage(pathname)) {
    API = "https://author.prod.aem.col.movistar.es";
  } else if (isSSRImage(pathname)) {
    API = "https://dev.ssr.col.movistar.es";
  } else {
    API = "https://col10mgmt.tsm.inet";
  }

  return API;
};

const createExtensionImage = ({
  src,
  extension,
  oe = '',
  width = '',
  hash,
  apiHost = true,
}) => {
  let pathname = makeUpSrcForApi(src);
  const API = getApiHost(pathname);
  pathname = pathname.split('.');
  pathname.pop();
  let queryParamsString = ';';
  const querysParams = [];
  if (width) {
    querysParams.push(`w=${width}`);
  }
  if (oe) {
    querysParams.push(`oe=${oe}`);
  }
  if (hash && currentEnv !== 'dev' && currentEnv !== 'local') {
    querysParams.push(`hash=${hash}`);
  }

  if (querysParams.length > 0) {
    queryParamsString = `?${querysParams.join('&')}`;
  }

  if (pathname && pathname[0] && isSSRImage(pathname[0])) {
    return `${apiHost ? API : ''}${pathname[0]}-${width ? `${width}w.`: ''}${extension}${queryParamsString}`;
  }

  return `${apiHost ? API : ''}${makeUpSrcForApi(pathname[0])}.${extension}${queryParamsString}`;
};

const getExtensionOfSrc = (src) => {
  let extension = '';
  try {
    const srcSplit = src.split('?');
    const pathname = srcSplit[0].split('.');
    extension = [pathname[pathname.length - 1]];
  } catch (e) {
    console.error('Enlace mal contribuido', src);
  }
  return extension;
};

const getImageId = (entryPoint = '') => {
  let tmp = entryPoint.split('.');
  tmp = tmp[tmp.length - 1];
  tmp = tmp.split('-');
  return tmp[tmp.length - 1];
};

const createSrcSet = ({ src, oe, compressionByResize, hash }) => {
  let sizesToGenerate = [...DEFAULT_SIZES];
  if (!compressionByResize) {
    sizesToGenerate = [sizesToGenerate.pop()];
  }
  const srcSet = [];
  [...VALID_EXTENSIONS, oe].forEach((extension) => {
    let srcSetOfExtension = '';
    sizesToGenerate.forEach((size) => {
      const w = size.replace('w', '');
      const newSrc = createExtensionImage({
        src,
        hash,
        extension,
        apiHost: true,
        oe,
        width: w,
      });
      srcSetOfExtension = `${srcSetOfExtension}${newSrc} ${size},`;
    });
    srcSet.push(srcSetOfExtension);
  });
  return srcSet;
};



let defaultHash = `env-${process.env.NEXT_PUBLIC_BUNDLE_ID}`;
if (!process.env.NEXT_PUBLIC_BUNDLE_ID) {
  defaultHash = `${getTimehash(36, true, false)}`
}

/**
 * Componente que permite representar una imagen, y redimensionarla en base al tamaño de pantalla
 */
const Imagen = (props) => {
  const {
    src,
    alt,
    title,
    fill,
    width,
    hash = defaultHash,
    height,
    objectFit,
    objectPosition,
    className,
    cms,
    cmsHandler = null,
    cyData,
    priority,
    onLoadCapture,
    onLoadingComplete,
    compressionByResize,
    display
  } = props;

  const renderCmsLabel = () => {
    return (<span
      className={`image image-${getImageId()} element-editable`}
      onClick={cms && cmsHandler ?
        () => cmsHandler({
          ...props,
          name: 'c-image'
        }) : null}
    >
      Imagen
    </span>)
  }

  if (!src || !display) {
    if (cms && cmsHandler) {
      return renderCmsLabel();
    }
    return null;
  }
  const oe = getExtensionOfSrc(src);
  let srcSetArray = [];
  //Genera srcset solo para los entornos que van al servicio de ssr
  if (currentEnv === 'prod' || isSSRImage(src)) {
    srcSetArray = createSrcSet({ src, oe, compressionByResize, hash });
  }
  const basePictureSrc = srcSetArray?.[srcSetArray.length - 1];
  const objectStyle = {
    objectFit: objectFit || undefined,
    objectPosition: objectPosition || undefined,
  };

  // Classname
  const finalClassName = `image${objectFit ? ` object-fit--${objectFit}` : ''}${objectPosition ? ` object-fit--${objectPosition}` : ''
    } ${className}`;

  return (
    <>
      {cmsHandler && (
        renderCmsLabel()
      )}
      <picture>
        {oe !== 'svg' &&
          srcSetArray && srcSetArray.map((srcSet, index) => {
            const srcSetExtension = VALID_EXTENSIONS[index] || oe;
            return <source key={srcSet} srcSet={srcSet} type={`image/${srcSetExtension}`} />;
          })}
        <Image
          style={objectStyle}
          alt={alt}
          title={title}
          srcSet={basePictureSrc}
          src={createExtensionImage({ src, extension: oe, oe, hash })}
          width={width}
          unoptimized
          height={height}
          fill={fill}
          className={finalClassName}
          data-cy={cyData}
          priority={priority}
          onLoadCapture={onLoadCapture}
          onLoadingComplete={onLoadingComplete}
        />
      </picture>
      <style jsx>{`.c-image {
  &--show {
    opacity: 0 !important;
  }

  &--hide {
    opacity: 0 !important;
  }
}
`}</style>
    </>
  );
};

Imagen.propTypes = {
  /**
   * {
   *  "info": "Url de la imagen que empiece por /estaticos/ o /content/dam/",
   *  "kind": "both",
   *  "beautifulName": "Url"
   * }
   */
  src: PropTypes.string.isRequired,
  /**
   * {
   *  "info": "Texto alternativo de la imagen para accesibilidad",
   *  "kind": "both",
   *  "beautifulName": "Alt"
   * }
   */
  alt: PropTypes.string.isRequired,
  /**
   * {
   *  "info": "Título de la imagen, se muestra como tooltip en algunos navegadores.",
   *  "kind": "both",
   *  "beautifulName": "Titulo"
   * }
   */
  title: PropTypes.string,
  /**
   * {
   *  "info": "Ancho en píxeles de la imagen. Obligatorio excepto cuando se especifica la propiedad fill",
   *  "kind": "dev",
   *  "beautifulName": "Ancho"
   * }
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * {
   *  "info": "Alto en píxeles de la imagen. Obligatorio excepto cuando se especifica la propiedad fill.",
   *  "kind": "dev",
   *  "beautifulName": "Altura"
   * }
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * {
   *  "info": "Estirará la imagen para ajustarse al contenedor padre en lugar de usar 'width' y 'height'. Cuando es 'true' es OBLIGATORIO asignarle al contenedor padre uno de los siguientes estilos: position: 'relative', position: 'fixed' o position: 'absolute'.",
   *  "kind": "dev",
   *  "beautifulName": "Rellenar espacio"
   * }
   */
  fill: PropTypes.bool,
  /**
   * {
   *  "info": "Establece cómo se debe redimensionar el contenido, para que se ajuste a su contenedor.",
   *  "kind": "dev",
   *  "beautifulName": "Tipo de rellenado"
   * }
   */
  objectFit: PropTypes.oneOf([
    'fill',
    'contain',
    'cover',
    'none',
    'scale-down',
  ]),
  /**
   * {
   *  "info": "Especifica la alineación del contenido del elemento dentro del elemento padre.",
   *  "kind": "dev",
   *  "beautifulName": "Tipo de rellenado"
   * }
   */
  objectPosition: PropTypes.string,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Añade etiqueta dom para priorizar su carga, útil si dicha imágen, está visible, en el aterrizaje a una página",
   *  "kind": "dev",
   *  "beautifulName": "Carga prioritaria"
   * }
   */
  priority: PropTypes.bool,
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
  /**
   * {
   *  "info": "Booleano para controlar que se pinte o no el componente",
   *  "kind": "dev",
   *  "beautifulName": "Display"
   * }
   */
  display: PropTypes.bool,
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
  /**
   * {
   *  "info": "Handler para eventos on load de next image",
   *  "kind": "dev",
   *  "beautifulName": "onLoadCapture"
   * }
   */
  onLoadCapture: PropTypes.func,
  /**
   * {
   *  "info": "Handler para eventos on loading de next image",
   *  "kind": "dev",
   *  "beautifulName": "onLoadCaptuonLoadingCompletere"
   * }
   */
  onLoadingComplete: PropTypes.func,
  /**
   * {
   *  "info": "Booleano que habilita la compresión por redimensionamiento",
   *  "kind": "dev",
   *  "beautifulName": "compressionByResize"
   * }
   */
  compressionByResize: PropTypes.bool,
};

Imagen.defaultProps = {
  fill: false,
  cms: null,
  cmsHandler: null,
  priority: false,
  className: '',
  cyData: '',
  onLoadCapture: null,
  onLoadingComplete: null,
  display: true,
  compressionByResize: true,
};

export default Imagen;
