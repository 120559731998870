import React, { useEffect, useRef, useState } from "react";
import CustomLink from "@/bit/components/components.custom-link";
import Text, { TEXT_COLOR, TEXT_FONT_WEIGHT, TEXT_SIZE, TEXT_WRAP } from "@/bit/components/components.text";

const Header5SecondaryMenu = ({ secondaryMenu }) => {
  const [scrolledMobile, setScrolledMobile] = useState(false);
  const scrolleableSecondaryMenu = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      const itemSelected = scrolleableSecondaryMenu.current.querySelector(".active");
      if (itemSelected) {
        const containerRect = scrolleableSecondaryMenu.current.getBoundingClientRect();
        const itemRect = itemSelected.getBoundingClientRect();
                
        // Calcular la posición relativa del elemento seleccionado dentro del contenedor
        const positionSelectedNavItem = itemRect.left - containerRect.left;
        scrolleableSecondaryMenu.current.scrollLeft = positionSelectedNavItem;
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    if (scrolledMobile) {
      document.querySelector('body').style.paddingTop = '105px';
    } else {
      document.querySelector('body').style.paddingTop = isMobile ? '56px': '80px';
    }
  }, [scrolledMobile]);

  const handleScroll = () => {
    const isScrolledMobile = window.scrollY > 60 && window.innerWidth <= 768;
    setScrolledMobile(isScrolledMobile);
  };

  const headerStyle = {
    position: scrolledMobile ? 'fixed' : 'static',
    top: scrolledMobile ? '56px' : 'auto',
    zIndex: 9999,
    width: '100%',
  };

  return (
    <>
      {secondaryMenu && secondaryMenu.items && (
        <div className="header5-secondary-menu" style={headerStyle}>
          <div className="container">
            <div className="secondary-menu" ref={scrolleableSecondaryMenu}>
              {
                Object.entries(secondaryMenu.items).map(([key, item]) => (
                  <div className={`secondary-menu-item ${secondaryMenu.selectedLink === item.id ? 'active' : ''}`} key={key}>
                    <CustomLink
                      href={item.link}
                      ariaLabel={item.ariaLabel}
                      title={item.title}
                    >
                      <Text
                        text={item.text}
                        fontType={TEXT_FONT_WEIGHT.REGULAR}
                        size={TEXT_SIZE.SUBTITLE2}
                        wrap={TEXT_WRAP.NOWRAP}
                        color={secondaryMenu.selectedLink === item.id ? TEXT_COLOR.WHITE : TEXT_COLOR.GREY3}
                        display="block"
                      />
                    </CustomLink>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      )}
      <style jsx>{`.header5-secondary-menu {
  display: flex;
  align-items: center;
  background-color: #0b2739;
  margin-bottom: 0.5rem;

  .secondary-menu {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.1rem 0 0.1rem 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .secondary-menu-item {
      display: flex;
      align-items: center;
      height: 2.5rem;
      text-underline-offset: 4px;
      &.active {
        box-sizing: border-box;
        text-decoration: solid underline white 2px;
      }
      :hover {
        text-decoration: solid underline white 1px;
        color: white!important;
      }
    }
    @media screen and (min-width: 768px) {
      height: 3rem;
    }
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 1rem;
  }
}
`}</style>
    </>
  );
};
export default Header5SecondaryMenu;
