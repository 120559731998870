import React from "react";
import PropTypes from "prop-types";
import LinkGroup from "./link-group";

const MainLinkList = ({ data }) => {
  return (
    <>
      <div className="main-link-list-section container">
        <div className="content">
          {data &&
            data.map((d) => (
              <LinkGroup title={d?.title} items={d?.items} key={d?.title} />
            ))}
        </div>
      </div>
      <style jsx>{`.main-link-list-section {
  margin: 0 auto;
}
.main-link-list-section .content {
  padding: 0.875rem 0;
}

@media screen and (min-width: 768px) {
  .main-link-list-section .content {
    padding-top: 0.875rem;
    padding-bottom: 2.75rem;
    height: min-content;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-content: stretch;
    align-items: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .main-link-list-section .content {
    padding: 1.75rem 0;
    height: min-content;
    width: 100%;
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: stretch;
    align-items: flex-start;
  }
}
`}</style>
    </>
  );
};

export default MainLinkList;

MainLinkList.propTypes = {
  /**
   * {
   *  "info": "Lista de objetos con el título del bloque y los datos de los enlaces anidados",
   *  "kind": "both",
   *  "beautifulName": "Información del bloque"
   * }
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

MainLinkList.defaultProps = {
  data: [],
};
